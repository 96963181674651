import React, {lazy, Suspense} from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Loading from 'components/layout/Loading';
import {fetchAllConfig} from './import-config';

fetchAllConfig().then(() => {
  const App = lazy(() => import('./App'));
  const container = document.getElementById('root');
  const root = createRoot(container);
  return root.render(
    <Suspense fallback={<Loading />}>
      <App />
    </Suspense>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
